.Pages{
    width: 100%;
    padding:0 34px;
    margin-bottom: 34px;
    .total {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        li {
            padding:0 26px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:nth-child(2n-1){
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .photosAdd {
        background-color: #eee;
        position: relative;
        overflow: hidden;
        input {
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            top:0;
            cursor: pointer;
            opacity: 0;
        }
    }
    .headerBottom {
        margin-bottom: 20px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        .right {
            display: flex;
            align-items:center;
        }
        .left {
            display: flex;
            align-items:center;
            font-weight: 500;
            color: #717f8c;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            img {
                width:10px;
                margin:0 8px
            }
            span {
                opacity: .7;
                // font-weight: 400;
            }
        }
    }

    .row {
        display: flex;
        width: 100%;
        flex: 1;
        .container {
            padding-right: 30px;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            &.full {
                flex:1;
                max-width: 100%;
            }
            &.w2 {
                flex: 0 0 66.666666%;
                max-width: 66.666666%;
            }
            &.w25 {
                flex: 0 0 25%;
                max-width: 25%;
            }
            &.wHalf {
                flex: 0 0 50%;
                max-width: 50%;
            }
            &:last-child{
                padding-right: 0px;
            }
        }
    }
    .p {
        padding: 26px;
    }
    .px {
        padding-left: 26px;
        padding-right: 26px;
    }
    .list {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        label {
            width:28%;
            flex: none;
            margin-bottom: 0px;
            color: var(--gray-3);
            font-weight: 400;
            margin-right: 15px;
            font-size: 13px;
        }
    }
    .buttonUpload {
        position: relative;
        overflow: hidden;
        input {
            position: absolute;
            top: 0;
            left: 0;
            right:0;
            bottom:0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .button {
        background-color: var(--white-1);
        color: #3F4254;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid var(--white-1);  
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            height: 44px;
            line-height: 44px;
            padding: 0 12px;
            cursor: pointer;
        }
        svg {
            margin-right: 10px;
            cursor: pointer;
            g [fill]{
                fill:#7E8299
            }
        }
    }
    .date {
        position: absolute;
        top: 50px;
    }
    .search {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray-3);
        margin-bottom: 18px;
    }
 .contentWhite {
    width: 100%;
    position: relative;
    box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
    background-color: var(--white-bg);
    border-radius: 5px;
    padding:0;
    display: inline-block;
    .subTitle {
        font-style: 34px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0px;
    }
}
&.edit {
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0px;
        h3 {
            margin-top: 0px;
            margin-bottom: 0px;
            color: var(--gray);
            font-weight: 500;
            letter-spacing: .05rem;
            font-size: 1.2rem;
        }
        svg {
            cursor: pointer;
            [fill]{
                fill: var(--gray-3);
            }
            &:hover [fill]{
                fill:#5A8DEE
            }
        }
        .buttons {
            display: flex;
        }
    }
    .tab {
        display:flex;
        margin-bottom: 25px;
        button {
            width:auto;
        }
    }
    .tabContent {
        box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
        background-color: var(--white-bg);
        border-radius: 5px;
        margin-top: 30px;
    }
    .submit {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            font-weight: 400;
            font-size: 15px !important;
        }
    }
    .textarea {
        resize: none;
        overflow: hidden;
        min-height: 50px;
        max-height: 100px;  
        width: 100%;
        border: 0px;
        color: var(--gray);
        font-size: 15px;
        font-family: inherit;
        padding:0;
        margin-top: 15px;
        background-color: transparent;
    }
    hr {
        opacity: .2;
        margin: 0;
    }
    .top{
        display: flex;
        .avatar {
            display: flex;
            justify-content:center;
            width:140px;
            height:140px;
            border-radius: 100%;
            overflow: hidden;
            border: 3px solid #fff;
            box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
            margin-right: 20px;
            position: relative;
            opacity: 1;
            transition: .2s all;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
            img {
                max-height: none;
                min-width: 100%;
                min-height: 100%;
            }
            input {
                position: absolute;
                cursor: pointer;
                left:0;
                top:0;
                right:0;
                bottom:0;
                opacity: 0;
            }
        }
        .p {
            display: flex;
            width: 100%;
        }
        b {
            font-weight: 500;
        }
        .right {
            flex:1;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            .nickname {
                display: flex;
                flex-direction: column;
                b {
                    font-weight: 400;
                    margin-bottom: 0px;
                    font-size: 30px;
                }
                small {
                    opacity: .7;
                }
            }
            .bottom {
                display: flex;
                margin-top: 20px;
                div {
                    flex:1;
                    display: flex;
                    flex-direction: column;
                    margin-right: 20px;
                    b {
                        margin-top: 5px;
                    }
                }
            }
        }
        .stats {
            height:192px;
            display: flex;
            flex:none;
            flex-direction: column;
            .header {
                display: flex;
                flex:1;
                height: 50%;
                border-left: 1px solid var(--gray-2);
                border-bottom: 1px solid var(--gray-2);
                &:last-child {
                    border-bottom: 0;
                }
                .item {
                    border-right: 1px solid var(--gray-2);
                    height:100%;
                    display: flex;
                    flex-direction: column;
                    justify-content:center;
                    text-align: center;
                    width:170px;
                    &:last-child {
                        border-right: 0px;
                    }
                    b {
                        font-size: 26px;
                    }
                    p {
                        margin-top: 5px;
                        margin-bottom: 0px;
                        font-size: 15px;
                        color:var(--gray-3)
                    }
                }
            }
        }
    }
    .photos {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        li {
            width: 120px;
            height: 120px;
            border-radius: 5px;
            overflow:hidden;
            margin-right: 10px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            position: relative;
            .close {
                position: absolute;
                top:5px;
                right:5px;
                border-radius: 3px;
                background-color: red;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                cursor: pointer;
            }
            img {
                min-width: 100%;
                min-height: 100%;
                max-height: none;
                transition:.2s all;
                cursor: pointer;
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
    .lastMessagesList {
        padding: 0px;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow: auto;
        margin-top: 20px;
        p {
            margin:0
        }
        .message {

            word-wrap: break-word;
            word-break: break-word;
            margin-bottom: 0px;
            margin-top: 5px;
            font-size: 14px;
            height: 20px;
            overflow: hidden;
        }
        li {
            display: flex;
            // border-bottom: 1px solid var(--gray-2);
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 26px;
            padding-right: 26px;
            align-items: center;
            &:nth-child(2n - 1){
                background-color: rgba(0, 0, 0, 0.04);
            }
            .avatar {
                width:44px;
                height:44px;
                border-radius: 100%;
                overflow: hidden;
                display: flex;
                align-items:center;
                justify-content: center;
                flex:none;
                position: relative;
                img {
                    min-width: 100%;
                    min-height: 100%;
                }
                &:nth-child(3){
                    margin-left: auto;
                }
            }
            .messageContent {
                margin:0 15px;
            }
            small {
                font-size: 11px;
            }
        }
    }
    .lastMessages {
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow: auto;
        margin-top: 20px;
        li {
            display: flex;
            .avatar {
                width:44px;
                height:44px;
                border-radius: 100%;
                overflow: hidden;
                display: flex;
                align-items:center;
                justify-content: center;
                flex:none;
                img {
                    min-width: 100%;
                    min-height: 100%;
                }
                &:nth-child(3){
                    margin-left: auto;
                }
            }
            .messageContent {
                margin:0 15px;
            }
            small {
                font-size: 11px;
            }
            .message{
                background: #e1ffc7;
                border-radius: 0 5px 5px 5px;
                color: #000;
                clear: both;
                line-height: 18px;
                font-size: 15px;
                padding: 8px;
                position: relative;
                margin: 8px 0 1px 0;
                word-wrap: break-word;
                word-break: break-word;
                small {
                    display: inline-block;
                    float: right;
                    padding: 0 0 0 7px;
                    position: relative;
                    bottom: -4px;
                    color: rgba(0,0,0,.45);
                    font-size: 11px;
                }
                &:after {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border-color: transparent #e1ffc7 transparent transparent;
                    border-style: solid;
                    border-width: 0 10px 10px 0;
                    top: 0;
                    left: -10px;
                }
            }
            &.right {
                justify-content: flex-end;
                .message{
                    background: #e1ffc7;
                    border-radius: 5px 0 5px 5px;
                    &:after {
                        position: absolute;
                        content: "";
                        width: 0;
                        height: 0;
                        border-color: transparent transparent transparent #e1ffc7;
                        border-style: solid;
                        border-width: 0 0 10px 10px;
                        top: 0;
                        right: -10px;
                        left:auto;
                    }
                }
            }
        }
    }
    .payments {
        max-height: 400px;
        overflow: auto;
        margin-top: 20px;
    }
}
}
.listActivity {
padding: 0 26px;
max-height: 400px;
overflow: auto;
margin-top: 20px;
.list {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    p {
        font-size:13px;
        margin: 0;
        small {
            font-size:13px;
        }
    }
    .a {
        color: #3599fe;
        font-weight: 600;
        &.danger {
            color: #f64e60;
        }

    }
    .badge{
        position: relative;
        margin: 0;
        width: 4px;
        border-radius: 5px;
        height: 49px;
        margin-right: 10px;
        background-color: #ffa800;
        &.type_1 {
                background-color: #3599fe;
        }
        &.type_2 {
                background-color: #8950fc;
        }
        &.type_3 {
                background-color: #19c5bd;
        }
    }
}
}

@media(max-width:991px) {
    .Pages{
        padding:0 15px;
        .headerBottom {
            .left {
                flex-wrap: wrap;
            }
        }
        &.edit {

            .row {
                flex-direction: column;
                .container {
                    padding-right: 0px!important;
                    flex:1!important;
                    max-width: 100%!important;
                }
            }
            .tab {
                flex-wrap: wrap;
                button {
                    margin-left: 0px!important;
                    margin-right: 10px!important;
                    margin-bottom: 10px;

                }
            }
            .avatar {
                width: 100px;
                height: 100px;
                flex:none;
            }
            .top {
                .stats {
                    width: 100%;
                    .header {
                        .item {
                            width: 33%;
                        }
                    }
                }
                .p {
                    flex-wrap: wrap;
                    justify-content: center;
                    text-align: center;
                }
                flex-wrap: wrap;
            }
        }
    }
}