.switch {

    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    p {
        margin:-8px 0px 0px 15px;
        font-weight: bold;
        font-size: 18px;
    }
}
.heartSwitch {
    --duration: .45s;
    --stroke: #D1D6EE;
    --stroke-active: #ec4472;
    --fill: #fff;
    --fill-active: #ec638e;
    --shadow: #{rgba(#00093D, .25)};
    cursor: pointer;
    position: relative;
    transform: scale(var(--s, 1)) translateZ(0);
    transition: transform .2s;
    -webkit-tap-highlight-color: transparent;
    &:active {
        --s: .95;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        outline: none;
        border: none;
        pointer-events: none;
        z-index: 1;
        margin: 0;
        padding: 0;
        left: 1px;
        top: 1px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 1px 3px 0 var(--shadow);
        & + svg {
            width: 40px;
            height: 34px;
            fill: var(--fill);
            stroke: var(--stroke);
            stroke-width: 1px;
            stroke-linejoin: round;
            display: block;
            transition: stroke var(--duration), fill var(--duration);
            position: relative;
            top: -5px;
        }
        &:not(:checked) {
            animation: uncheck var(--duration) linear forwards;
        }
        &:checked {
            animation: check var(--duration) linear forwards;
            & + svg {
                --fill: var(--fill-active);
                --stroke: var(--stroke-active);
            }
        }
    }
}

@keyframes uncheck {
    0% {
        transform: rotate(-30deg) translateX(16.5px) translateY(8px);
    }
    50% {
        transform: rotate(30deg) translateX(9px);
    }
    75% {
        transform: rotate(30deg) translateX(4.5px) scaleX(1.1);
    }
    100% {
        transform: rotate(30deg);
    }
}

@keyframes check {
    0% {
        transform: rotate(30deg);
    }
    25% {
        transform: rotate(30deg) translateX(4.5px) scaleX(1.1);
    }
    50% {
        transform: rotate(30deg) translateX(9px);
    }
    100% {
        transform: rotate(-30deg) translateX(16.5px) translateY(8px);
    }
}


.switch2 {
    position: relative;
    margin: 20px auto;
    height: 26px;
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    padding:0 2px;
    .switchLabel {
      position: relative;
      display: block;
      z-index: 2;
      width: 58px;
      line-height: 26px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }
    
    .switchLabel:active {
      font-weight: bold;
    }
    /*
     * Note: using adjacent or general sibling selectors combined with
     *       pseudo classes doesn't work in Safari 5.0 and Chrome 12.
     *       See this article for more info and a potential fix:
     *       https://css-tricks.com/webkit-sibling-bug/
     */
    
    .switchInput {
      display: none;
    }
    
    .switchInput:checked + .switchLabel {
      color: rgba(255, 255, 255, 1);
      text-shadow: 0 1px rgba(255, 255, 255, 0.15);
      -webkit-transition: 0.15s ease-out;
      -moz-transition: 0.15s ease-out;
      -o-transition: 0.15s ease-out;
      transition: 0.15s ease-out;
    }
    
    
    .switchSelection {
      display: block;
      position: absolute;
      z-index: 1;
      top: 2px;
      left:2px;
      width: 33.3333333%;
      height: 22px;
        border-radius: 3px; /* border radius */
        background-color: var(--primary);
        box-shadow:  0 0 0;
        // -moz-box-shadow: 0 1px 3px rgba(0,0,0,.1), inset 0 1px 0 #000; /* drop shadow and inner shadow */
        // -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.1), inset 0 1px 0 #000; /* drop shadow and inner shadow */
        // box-shadow: 0 1px 3px rgba(0,0,0,.1), inset 0 1px 0 #000; /* drop shadow and inner shadow */
      -webkit-transition: left 0.15s ease-out;
      -moz-transition: left 0.15s ease-out;
      -o-transition: left 0.15s ease-out;
      transition: left 0.15s ease-out;
      &.step_2 {
        left: 33%;
      }
      &.step_3 {
        left: 66%;
      }
    }
    
  
  }  