.tableContent {
  color: var(--gray-3);
  width:100%;
  overflow-x: auto;
  // overflow: auto;
  .lastTd {
    min-width: 36px;
    padding-right:0px;
    display: flex;
  }
  .search {
    display:flex
  }
  .table {
    width:100%;
    &.breakDisable {
      tr{
        td {
          word-break: inherit!important;
        }
      }
    }
    .icon {
      position: absolute;
      margin-left: 10px;
      width: 20px;
      g [fill]{
        fill: var(--gray-3)
      }
    }
    .close {
      g [fill]{
        fill:red
      }
    }
    .check {
      g [fill]{
        fill:#1BC5BD
      }
    }
    .center {
      display: flex;
      width:100%;
      justify-content: space-between;
      align-items: center;
    }
    th, td {
      &:nth-child(1){
        padding-left: 25px!important;
      }
      &:last-child{
        padding-right: 25px!important;
      }
    }
    tfoot {
        tr {
            td {
              color: var(--gray-3);
              text-decoration: dashed;
              font-weight: 400;
              font-size: 16px;
              text-align: left;
              text-transform: uppercase;
              padding-top: 20px;
              border-top: 1px solid var(--gray-2);
              padding-right: 10px;
              font-weight: bold;
              cursor: pointer;
              &:nth-child(1){
                  padding-left: 0px;
              }
              &:last-child{
                  padding-right: 0px;
              }
            }
        }
    }
    thead {
        tr {
            th {
              color: var(--gray-3);
              text-decoration: dashed;
              font-weight: 400;
              font-size: 13px;
              text-align: left;
              text-transform: uppercase;
              padding-bottom: 20px;
              border-bottom: 1px solid var(--gray-2);
              padding-right: 10px;
              cursor: pointer;
              &:nth-child(1){
                  padding-left: 0px;
              }
              &:last-child{
                  padding-right: 0px;
              }
              svg {
                width:11px;
                margin-left:8px;
              }
            }
        }
    }
    tr {
      &.disabled {
        pointer-events: none;
        opacity: .5;
        button {
          display: none;
        }
      }
      td {
          word-break: break-all;
          padding-top: 5px;
          text-align: left;
          padding-bottom: 5px;
          padding-right: 10px;
      }
    }
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      overflow: hidden;
      background-color:var(--white-1);
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-right: 15px;
      svg{
        width:30px;
        g [fill] {
          fill: #3699FF;
        }
      }
      img {
        min-width: 100%;
        min-height: 100%;
        max-height: none;
      }
    }
    &.type_2 {
        tr{
            td{
              border-bottom: 1px solid var(--gray-2);
              padding-top: 5px;
              padding-bottom: 5px;
              b {
                  font-weight: 500;
              }
              p, b {
                  font-size: 13px;
                  display: block;
              }
            }
        }
    }
  }
  .paginations {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content:flex-end;
    padding:0 20px;
    .total {
      margin-right: auto;
    }
    ul {
      display:flex;
      border: 1px solid #464D5C;
      border-radius: 3px;
      li {
        &:nth-child(1){
          border-radius: 5px 0 0 5px;
          border-right: 1px solid #464D5C;
        }
        &:last-child{
          border-radius: 0 5px 5px 0;
          border-left: 1px solid #464D5C;
        }
        padding:5px 10px;
        color: var(--gray5);
        background-color: transparent;
        display:flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        transition:.2s all;
        &:hover {
          background-color:rgba(90,141,238,.2);
          border-color: var(--primary);
        }
        &.disabled {
          pointer-events: none;
          opacity: .6;
        }
        &.active {
          background-color:var(--primary);
          border-color: var(--primary);
          color:#fff
        }
      }
    }
  }
}