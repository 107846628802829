.dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 34px;
    flex-direction: column;
    .row {
        display: flex;
        width: 100%;
        flex: 1;
        .container {
            padding-right: 30px;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            &.full {
                flex:1;
                max-width: 100%;
            }
            &.w2 {
                flex: 0 0 66.666666%;
                max-width: 66.666666%;
            }
            &.wHalf {
                flex: 0 0 50%;
                max-width: 50%;
            }
            &:last-child{
                padding-right: 0px;
            }
        }
        .row {
            margin:0;
            .box {
                flex:1!important;
                max-width: 100%!important;
            }
        }
    }
    .half {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        display: flex;
        flex-direction: column;
        .box {
            height: auto;
        }
    }
    .content {
    }
    .box {
        margin-bottom: 30px;
        box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
        background-color: var(--white-bg);
        background-clip: border-box;
        border-radius: .267rem;
        flex:1;
        display: flex;
        flex-direction: column;
        height: 342px;
        position: relative;
        &.h2{
            height: 465px;
        }
        &.newMessages {
            // margin-right: 34px;
        }
        &.afilliate {
            .scroll {
                height: 342px;
                overflow: scroll;
                margin-top: 10px;
            }
        }
        &.paymentType {
            // flex:1;
            // width: 33%;
            // margin-right: 34px;
        }
        &.bestUser {
            padding: 22px 26px 26px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // margin-right: 34px;
            // flex:1;
            .down {
                .row {
                    display: flex;
                    align-items: flex-end;
                }
                .img {
                    width: 135px;
                    flex: none;
                    margin-left: 15px;
                }
                b {
                    color: #5A8DEE;
                    font-size: 32px;
                    font-weight: bold;
                }
                p {
                    font-weight: 400;
                }
            }
        }
        &.onlineUsers {
            // flex:1;
            // width: 33%;
            // margin-right: 34px;
            // height: 406px;
            overflow: hidden;
            .iconHeader{
                background: rgb(54,109,233);
                background: linear-gradient(146deg, rgba(54,109,233,1) 0%, rgba(103,149,254,1) 100%);   
                margin: 0;
                padding: 14px 0 24px 0;
                color: #fff;
                margin-bottom: -20px;
                b {
                    color: #fff;
                }
                .icon {
                    background-color: rgba(255,255,255,.17);
                    svg {
                        [fill]{
                            fill:#fff
                        }
                    }
                }
            }
            .listContent {
                // border-radius: 15px 15px 0 0;
                position: relative;
                z-index: 2;
                overflow: hidden;
                flex:1;
                display: flex;
                box-shadow: 0 0 10px rgba(0,0,0,.2);
            }
            .list {
                overflow: auto;
                flex:1;
                background-color: var(--white-bg);
                padding: 15px 22px;
                li {
                    margin-bottom: 8px;
                    b {
                        font-size: 14px;
                    }
                    p {
                        font-size: 14px;
                        margin:0
                    }
                    .name {
                        display: flex;
                        flex-direction: column;
                        p {
                            margin:0;
                        }
                    }
                }
            }
        }
        .chartList {
            display: flex;
            justify-content: space-around;
            .item {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                padding: 0 20px;
                .chart {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        margin-left: -15px;
                    }
                    b {
                        margin-left: -15px;
                        font-size: 24px;
                        font-weight:500;
                    }
                }
                .head {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 18px;
                        margin-right: 10px;
                        [fill]{
                            fill:var(--gray-5)
                        }
                    }
                }
            }
        }
        .iconHeader{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #828D99;
            font-size: 16px;
            margin: 18px 0 12px 0;
            font-weight: 400;
            p {
                margin-top: 10px;
                margin-bottom: 0px;
            }
            b {
                font-weight: 500;
                color: var(--gray);
                font-size: 26px;
            }
            .icon {
                width: 48px;
                height: 48px;
                border-radius: 100%;
                background-color: rgba(255,91,92,.17);
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width:22px;
                    height: 22px;
                    [fill]{
                        fill:#FF5B5C
                    }
                }
            }
            &.type_2 {
                .icon {
                    background-color: rgba(57,218,138,.17);
                    svg {
                        [fill]{
                            fill: #39DA8A;
                        }
                    }
                }
            }
        }
        .title_2 {

            color: var(--gray);
            font-size: 24px;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            small {
                font-weight: 500;
                font-size: 15px;
                margin-top: 5px;
            }
        }
        .waiting {
            display: flex;
            .item{
                flex:1;
                text-align: center;
                p {
                    font-size: 14px;
                    opacity: .7;
                    margin-bottom: 4px;
                }
            }
        }
        .list {
            display: flex;
            flex-direction: column;
            padding: 0px 0;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                span{
                    font-size: 15px;
                    color: var(--gray-3);
                }
                b{
                    color:var(--gray);
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
        }
        .hr {
            width: 100%;
            height: 1px;
            flex: none;
            background-color: var(--gray-2);
        }
        .px {
            padding-left: 26px;
            padding-right: 26px;
        }
        .left {
            flex:1
        }
        .right {
            border-left: 1px solid var(--gray-2);
            width: 240px;
            .title:nth-child(4){
                padding-top: 14px;
                padding-bottom: 2px;
            }
            .total {
                display: flex;
                flex-direction: column;
                b {
                    font-size: 20px;
                    color: #5A8DEE;
                    font-weight: 700;
                }
                .bar {
                    height: 6px;
                    border-radius: 18px;
                    background-color: var(--gray-1);
                    margin-top: 10px;
                    position: relative;
                    &:after{
                        content:"";
                        border-radius: 18px;
                        background-color: #5A8DEE;
                        box-shadow: 0 2px 6px 0 rgba(90,141,238,.6);
                        position: absolute;
                        bottom: 0;
                        top: 0;
                        left: 0;
                        width: 70%;
                    }
                }
            }
        }
        .title {
            display: flex;
            justify-content: space-between;
            padding: 22px 26px 14px 26px;
            align-items: center;
            h3 {
                margin-top: 0px;
                margin-bottom: 0px;
                color: var(--gray);
                font-weight: 500;
                letter-spacing: .05rem;
                font-size: 1.2rem;
            }
            svg {
                cursor: pointer;
                [fill]{
                    fill: var(--gray-3);
                }
                &:hover [fill]{
                    fill:#5A8DEE
                }
            }
            .buttons {
                display: flex;
            }
        }
    }
}

@media(max-width:991px){
    .dashboard{
        padding: 0 15px;
        &>.row {
            .container {
                flex:1!important;
                max-width: 100%!important;
                padding: 0px!important;
            }
            flex-direction: column;
        }
        .box {
            height:auto;
            margin-bottom: 15px;
            .title, .title2 {
                flex-wrap: wrap;
                .buttons {
                    flex-wrap: wrap;
                }
            }
            .right {
                width: 100%;
            }
        }

        .half {
            flex:1;
            max-width: 100%;
        }
        .paymentBox {
            .row {
                flex-direction: column;
            }
        }
    }
}