.Users{
    width: 100%;
    padding:0 34px;
    margin-bottom: 34px;
    .headerBottom {
        margin-bottom: 20px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        .right {
            display: flex;
            align-items:center;
        }
        .left {
            display: flex;
            align-items:center;
            font-weight: 500;
            color: #717f8c;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            img {
                width:10px;
                margin:0 8px
            }
            span {
                opacity: .7;
                // font-weight: 400;
            }
        }
    }
    .table {
        input {
            padding:10px 4px;
            border: 1px solid #ddd;
        }
    }
    .avatar{
        position:relative;
        width: 120px;
        height: 120px;
        border-radius: 5px;
        border: 4px solid #fff;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 15px;
        margin-bottom: 20px;
        margin-right: auto;
        .absolute{
            position: absolute;
            opacity: 0;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            cursor:pointer;
            z-index:2
        }
        .avatarImg{
            border-radius: 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f6f9;
            height: 100%;
            img {
                min-width:100%;
                min-height:100%;
                max-width: none;
                max-height: none;
            }
            svg {
                width:80px;
                height:80px;
                g [fill]{
                    fill: #3699FF
                }
            }
        }
    }
    .formGroup {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        flex-wrap: wrap;
        label {
            width:180px;
            flex: none;
        }
        input, textarea {
            height: 44px;
            padding: 0 12px;
            border-radius: 5px;
            font-size: 14px;
            color: var(--gray);
            background-color: var(--white-bg);
            border: 1px solid var(--gray-2);
            transition: all .2s;
            width: 320px;
            flex:none;
        }
        textarea {
            flex:1;
            height: 100px;
        }
    }
    .tabHeader {
        border-bottom: 1px solid var(--gray-2);
        display:flex;

        flex-wrap: wrap;
        padding:0 25px;
        li {
            height: 70px;
            display: flex;
            align-items: center;
            margin-right:25px;
            color: #7E8299;
            font-size:14px;
            font-weight: 500;
            transition: .2s all;
            flex:none;
            cursor: pointer;
            &.active {
                color:#3699FF;
                border-bottom: 1px solid #3699FF;
            }
            &:hover {
                color:#3699FF
            }
        }
    }
    .tabContent {
        padding:25px;
        li {
            display:none;
            &.active {
                display: inline;
            }
            .list {
                display: flex;
                label {
                    width:180px;
                    flex: none;
                }
            }
        }
    }
    .row {
        display: flex;
        width: 100%;
        flex: 1;
        .container {
            padding-right: 30px;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            &.full {
                flex:1;
                max-width: 100%;
            }
            &.w2 {
                flex: 0 0 66.666666%;
                max-width: 66.666666%;
            }
            &.w25 {
                flex: 0 0 25%;
                max-width: 25%;
            }
            &.wHalf {
                flex: 0 0 50%;
                max-width: 50%;
            }
            &:last-child{
                padding-right: 0px;
            }
        }
    }
    .p {
        padding: 26px;
    }
    .px {
        padding-left: 26px;
        padding-right: 26px;
    }
    .list {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        label {
            width:28%;
            flex: none;
            margin-bottom: 0px;
            color: var(--gray-3);
            font-weight: 400;
            // margin-right: 15px;
            font-size: 13px;
        }
    }
    .buttonUpload {
        position: relative;
        overflow: hidden;
        input {
            position: absolute;
            top: 0;
            left: 0;
            right:0;
            bottom:0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .button {
        background-color: var(--white-1);
        color: #3F4254;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid var(--white-1);  
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            height: 44px;
            line-height: 44px;
            padding: 0 12px;
            cursor: pointer;
        }
        svg {
            margin-right: 10px;
            cursor: pointer;
            g [fill]{
                fill:#7E8299
            }
        }
    }
    .date {
        position: absolute;
        top: 50px;
    }
    .search {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray-3);
        margin-bottom: 18px;
    }
    .contentWhite {
        width: 100%;
        position: relative;
        box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
        background-color: var(--white-bg);
        border-radius: 5px;
        padding:0;
        display: inline-block;
        .subTitle {
            font-style: 34px;
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 0px;
        }
    }
    .total {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        li {
            padding:0 26px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:nth-child(2n-1){
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
}

@media(max-width:991px){
    .Users{
        padding:0 15px;
    }
}