
.general {
    display: flex;
    background-color: var(--gray-1);
    transition: .2s all;
    .left {    
        border-right: 1px solid var(--gray-2);
        width: 260px;
        position: fixed;
        left:0;
        top:0;
        bottom:0;
    }
    .content {
        margin-left: 260px;
        flex:1;
        padding-top: 72px;
    }
}
@media(max-width:991px){
    .general {
        .content {
            margin-left: 0px;
            width: 100%;
        }
    }
}