
.buttons {
  box-shadow: 0px 0px 0px;
  padding: 0px 15px;
  border-radius: 50px;
  height:52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-transform: uppercase;
  transition:.2s all;
  width: 100%;
  box-shadow: 0 0 0;
  border:0px;
  white-space: nowrap;
  cursor: pointer;
  &:disabled,
  &[disabled]{
    opacity: .5;
    cursor: auto;
  }

  &.primaryOutline {
    color: var(--primary);
    border: 2px solid var(--primary);
    background-color: var(--white-bg);
    &:hover {
      background-color: var(--primary);
      color: #fff
    }
  }
  &.type_1{
    border: 0 solid transparent;
    height: 42px;
    padding: 0 1.5rem;
    font-size: 1rem;
    line-height: 1.6rem;
    border-radius: .267rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-transform:capitalize;
    font-weight: 500;
    box-shadow: 0 2px 4px 0 rgba(90,141,238,.5)!important;
    &:hover {
        box-shadow:0 4px 12px 0 rgba(90,141,238,.6)!important;
    }
  }
  &.message {
    margin: 10px 0 0px 0;
    height: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0px;
    color: #fff;
    border-radius: 0px;
    font-size: 13px;
    font-weight: 600;
    border-top: 1px solid rgba(255,255,255,0.32);
    // background-color: #54bedf;
    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
    cursor: pointer;
    button{
      background-color:transparent;
      color: #fff;
      font-size: 13px;
      height: 45px;
      width:100%;
      font-weight: 600;
      text-transform: uppercase;
    }
    span {
      flex: 1;
      display: flex;
      justify-content:center;
      align-items: center;
    }
    i{
      border-radius: 0 5px 5px 0;
      background: #42a3c1;
      height: 100%;
      padding: 0 8px;
      margin-left: auto;
      font-size: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active{
        background: #f1f2f5!important;
        color: #f3d227
      }
    }
    &:hover {
      background-color: var(--primary);
      color: #fff
    }
  }
  &.danger {
    color: #fff;
    background-color: #f56667;
    &:hover {
      background-color: #f13e3f;
      color: #fff
    }
  }
  &.blue {
    color: #fff;
    background-color: #3498da;
    &:hover {
      background-color: #5fa9da;
      color: #fff
    }
  }
  &.orange {
    background-color: #f39c12;
    color: #fff;
    &:hover {
      background-color: #e67e22;
    }
  }
  &.danger_2 {
    color: #fff;
    background-color: #dc3545;
    &:hover {
      background-color: #bd2130;
      color: #fff
    }
  }
  &.primary {
    color: #fff;
    background-color: var(--primary);
    &:hover {
      background-color: var(--primary-dark);
      color: #fff
    }
  }
  &.primaryLigth {
    background-color: rgba(90,141,238,.17);
    color: #5A8DEE;
    svg [fill]{
      fill:#5A8DEE;
    }
    box-shadow:0 0 0!important;
    &:hover {
      background-color: #719DF0;
      color: #fff;
      svg [fill]{
        fill:#fff;
      }
    }
  }
  &.secondary {
    color: #fff;
    background-color: var(--secondary);
    &:hover {
      background-color:var(--secondary-dark);
      color: #fff
    }
  }
  &.secondary2 {
    color: #fff;
    background-color: var(--secondary);
    &:hover {
      background-color:var(--secondary-dark);
      color: #fff
    }
  }
  &.gold {
    color: #fff;
    background-color: #f6c358;
    &:hover {
      background-color: #e8a34e;
      color: #fff
    }
  }
  &.dark {
    color: #fff;
    background-color: var(--dark-color);
    &:hover {
      background-color: var(--dark-color-dark);
      color: #fff
    }
  }
  &.success {
    padding: 12px 15px;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    background-color: var(--success);
    &:hover {
      background-color: var(--success-dark);
    }
  }
}
