.DashboardFiltre{
    display:flex;
    width:100%;
    padding: 20px;
    border-bottom: 1px solid var(--gray-2);
    margin-bottom: 20px;
    input, select {
       min-width: 80px;
    }
    .row {
        display: flex;
        flex-direction: column;
        flex:1;
        ul {
            margin-bottom: 0!important;
            li {
                width:140px;
                &.x2{
                    width:290px
                }
            }
        }
    }
    ul{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom: 0!important;
        flex-wrap: wrap;
        &:nth-child(2){
            margin-top: 10px;
        }
        &:nth-child(3){
            margin-top: 10px;
        }
        li {
            margin-right: 10px;
            &:nth-child(1){
                flex:1;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
}

@media(max-width:991px){
    .DashboardFiltre{
        ul{
            li {
                flex:1;
                margin-right: 4px;
                margin-left: 4px;
                margin-bottom: 8px;
            }
        }
    }
}