.sidebar {
    border-right: 1px solid var(--gray-2);
    width: 260px;
    position: fixed;
    left:0;
    top:0;
    bottom:0;
    padding:12px 14px;
    z-index: 9;
    background-color: var(--gray-1);
    .menuBtn {
        display: none;
    }
    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-weight: 700;
        font-size: 20px;
        color: #5a8dee;
    }
    .main {
        border: 1px solid var(--gray-2);
        background-color: var(--white-bg);
        border-radius: 3px;
        padding:10px 12px;
        font-size: 15px;
        color: #8494A7;
        margin-top: 12px;
        display: flex;
        transition: .2s all;
        cursor: pointer;
        img, svg {
            width: 24px;
            margin-right: 12px;
        }
        svg g [fill] {
            fill: #5a8dee;
        }
        svg g [stroke] {
            stroke: #5a8dee;
        }
        &:hover {
            color:#5a8dee;
        }
    }
    .title {
        color: #BAC0C7;
        letter-spacing: 1px;
        font-size: 13px;
        font-weight: 500;
        margin-top: 20px;
        text-transform: uppercase;
        padding:0px 12px;
        margin-bottom: 5px;
    }
    .menu {
        li {
            .a {
                display: flex;
                align-items: center;
                font-size: 15px;
                border: 1px solid transparent;
                background-color: transparent;
                border-radius: 3px;
                padding:12px 10px;
                transition: .2s all;
                cursor: pointer;
                &:hover {
                    color:#5a8dee;
                    svg g [fill] {
                        fill: #5a8dee;
                    }
                    svg g [stroke] {
                        stroke: #5a8dee;
                    }
                }
                svg g [fill] {
                    fill: var(--gray-3);
                    transition: fill .2s ease;
                }
                svg g [stroke] {
                    stroke: var(--gray-3);
                }
                .leftArrow {
                    margin:0 15px 0 10px;
                }
                .arrow {
                    width: 10px;
                    margin-right: 0;
                    margin-left: auto;
                    transform: .2s all;
                    transform: rotate(0deg);
                    transition: .2s all;
                }
                img, svg {
                    height: 24px;
                    margin-right: 14px;
                }
                &.active {
                    border: 1px solid var(--gray-2);
                    background-color: var(--white-bg);
                    .arrow{
                        transform: rotate(90deg);
                    }
                    svg g [fill] {
                        fill: #5a8dee;
                    }
                    svg g [stroke] {
                        stroke: #5a8dee;
                    }
                }
            }
            &.drop {
                .a {
                    border: 1px solid var(--gray-2);
                    border-bottom: 0px;
                    // background-color: #FAFBFB;
                    background-color: var(--white-bg);
                    .arrow{
                        transform: rotate(90deg);
                    }
                }
                ul {
                    max-height: 300px;
                }
            }
            ul {
                max-height: 0px;
                transition: .2s all;
                overflow: hidden;
                position: relative;
                ::before {
                    content: "";
                    height: 1px;
                    position: absolute;
                    top: 0;
                    left: 10px;
                    right: 10px;
                    background-color: var(--gray-2);
                }
                li{
                    .a {
                        border-top: 0px!important;
                        border-bottom: 0px!important;
                        font-size: 14px;
                    }
                    &:last-child {
                        .a {
                            border-bottom: 1px solid var(--gray-2)!important;;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width:991px){
    .sidebar {
        left: -260px;
        transition: .2s all;
        &.active {
            left: 0;
        }
        .menuBtn {
            display: block;
            position: fixed;
            left: 12px;
            width: 34px;
            height: 34px;
            top: 14px;
            [fill]{
                fill:var(--gray)
            }
        }
    }
}