.home {
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        width: 956px;
        background-color: #fff;
        box-shadow: -8px 20px 25px 0 rgba(25,42,70,.3);
        border-radius: 3px;
        display: flex;
        overflow: hidden;
        .left, .right {
            flex:1;
            padding: 3rem;
        }
        .right {
            background-color: var(--gray-1);
        }
    }
}