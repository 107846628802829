body {
    height: -webkit-fill-available;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: var(--gray);
    // color: var(--gray-3);
    margin: 0;
    overflow-x: hidden;
}
.CodeMirror{

  color: var(--gray)!important;
  background-color: var(--white-bg)!important;
  border: 1px solid var(--gray-2)!important;
}
.editor-toolbar, .editor-toolbar i.separator {
  border-color: var(--gray-2)!important;;
}
.editor-toolbar .easymde-dropdown, .editor-toolbar button {
  color: var(--gray)!important;
}
.css-2b097c-container {
  flex:1
}
.css-yk16xz-control {

  background-color: var(--white-bg)!important;
  border: 1px solid var(--gray-2)!important;
  color:var(--gray)!important;
}
.badge {
  padding:3px 8px;
  border-radius: 5px;
  font-size:12px;
  &.danger {
    color: #F64E60;
    background-color: #FFE2E5;
  }
  &.warning {
    color: #a58e36;
    background-color: #ffeaa9;
  }
  &.primary {
    color: #3699FF;
    background-color: #E1F0FF;
  }
  &.success {    
    color: #1BC5BD;
    background-color: #C9F7F5;
  }
  &.paypal {    
    color: #fff;
    background-color: #002b89;
  }
  &.directbanking {    
    color: #fff;
    background-color: #ee7f02;
  }
  &.paysafecard {    
    color: #fff;
    background-color: #018ac9;
  }
  &.prepayment {    
    color: #fff;
    background-color: #1BC5BD;
  }
  &.creditcard {    
    color: #fff;
    background-color: #ed0308;
  }
}
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  opacity: 0;
  pointer-events: none;
  width: 320px;
  background-color: #fff;
  color: #7E8299;
  text-align: center;
  padding: 12px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  // bottom: -100%;
  top:100%;
  font-size:12px;
  text-align: left;
  transition:.2s all;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.tooltip:hover .tooltiptext {
  opacity: 1;
  pointer-events: all;
}
body .apexcharts-canvas .apexcharts-gridlines-horizontal .apexcharts-gridline, body .apexcharts-canvas .apexcharts-radialbar-track path {
  stroke: var(--gray-4);
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray-3); 
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
html {
  height: -webkit-fill-available;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    direction: ltr;
}
* {
  box-sizing: border-box;
    // font-family: 'IBM Plex Sans', sans-serif;
    font-family: 'Rubik', sans-serif;
}
svg#SvgjsSvg1286 {
  text {
    opacity: 0;
    transition: .2s all;
  }
  &:hover {
    text {
      opacity: 1;
    }
  }
}
.open {
  display: block!important;
  opacity: 1!important;
  pointer-events: auto!important;
}
.modalOpen {
  opacity: 1!important;
  pointer-events:auto!important
}
.modalContent {
  margin: 60px auto!important;
  opacity: 1!important;
}

strong a {
  display: inline-block;
}
.primary-bg-2 {
  background-color: #eb4f42;
  color: #fff;
  transition:.3s all;
  &:hover {
    background-color: #f3837b;
  }
}
.success-bg {
  background-color: #2ecc71;
  color: #fff;
  transition:.3s all;
  &:hover {
    background-color: #27ae60;
  }
}
// .primary-bg {
//   background-color: $primary-color;
//   color: #fff;
//   transition:.3s all;
//   &:hover {
//     background-color: $primary-color-dark;
//   }
// }
.blue {
  color: #3394cc;
}
.fixed-bg {
  background-position: center;
  background-size: cover;
}
.d-around {
  display: flex;
  justify-content: space-around;
}

.container {
    max-width: 1140px;
    margin:0 auto;
    position: relative;
}
a {
  text-decoration: none;
  color: inherit;
  display:block
}
img{
  max-width: 100%;
  max-height: 100%;
}
ul {
  padding:0px;
  margin:0px;
}
ul li{
  list-style: none;
  
}
small {
  color:var(--gray-3)
}
.text-right {
  text-align: right
}
.colorWhite {
  color:#fff;
}
.whiteBg {
  background: #fff;
}
.d-block {
  display:block!important
}
.d-none {
  display:none!important
}
.d-flex {
  display:flex!important
}
.justify-content-center {
  justify-content:center!important
}
.align-items-center {
  align-items:center!important
}
.m-0 {
  margin:0px!important 
}
.mt-1, .my-1 {
  margin-top: .25rem!important;
}
.mt-2, .my-2 {
  margin-top: .5rem!important;
}
.mt-3, .my-3 {
  margin-top: 1rem!important;
}
.mt-4, .my-4 {
  margin-top: 1.5rem!important;
}
.mt-5, .my-5 {
  margin-top: 3rem!important;
}
.mb-1, .my-1 {
  margin-bottom: .25rem!important;
}
.mb-2, .my-2 {
  margin-bottom: .5rem!important;
}
.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}
.mb-4, .my-4 {
  margin-bottom: 1.5rem!important;
}
.mb-5, .my-5 {
  margin-bottom: 3rem!important;
}
.mr-1, .mx-1 {
  margin-right: .25rem!important;
}
.mr-2, .mx-2 {
  margin-right: .5rem!important;
}
.mr-3, .mx-3 {
  margin-right: 1rem!important;
}
.mr-4, .mx-4 {
  margin-right: 1.5rem!important;
}
.mr-5, .mx-5 {
  margin-right: 3rem!important;
}
.ml-1, .mx-1 {
  margin-left: .25rem!important;
}
.ml-2, .mx-2 {
  margin-left: .5rem!important;
}
.ml-3, .mx-3 {
  margin-left: 1rem!important;
}
.ml-4, .mx-4 {
  margin-left: 1.5rem!important;
}
.ml-5, .mx-5 {
  margin-left: 3rem!important;
}
.pt-1, .py-1 {
  padding-top: .25rem!important;
}
.pt-2, .py-2 {
  padding-top: .5rem!important;
}
.pt-3, .py-3 {
  padding-top: 1rem!important;
}
.pt-4, .py-4 {
  padding-top: 1.5rem!important;
}
.pt-5, .py-5 {
  padding-top: 3rem!important;
}
.pb-1, .py-1 {
  padding-bottom: .25rem!important;
}
.pb-2, .py-2 {
  padding-bottom: .5rem!important;
}
.pb-3, .py-3 {
  padding-bottom: 1rem!important;
}
.pb-4, .py-4 {
  padding-bottom: 1.5rem!important;
}
.pb-5, .py-5 {
  padding-bottom: 3rem!important;
}
.pr-1, .px-1 {
  padding-right: .25rem!important;
}
.pr-2, .px-2 {
  padding-right: .5rem!important;
}
.pr-3, .px-3 {
  padding-right: 1rem!important;
}
.pr-4, .px-4 {
  padding-right: 1.5rem!important;
}
.pr-5, .px-5 {
  padding-right: 3rem!important;
}
.pl-1, .px-1 {
  padding-left: .25rem!important;
}
.pl-2, .px-2 {
  padding-left: .5rem!important;
}
.pl-3, .px-3 {
  padding-left: 1rem!important;
}
.pl-4, .px-4 {
  padding-left: 1.5rem!important;
}
.pl-5, .px-5 {
  padding-left: 3rem!important;
}
.p-1 {
  padding:.25rem
}
.p-2 {
  padding:.5rem
}
.p-3 {
  padding:1rem
}
.p-4 {
  padding:1.5rem
}
.p-5 {
  padding:3rem
}
.w-100 {
  width:100%!important;
}
.cursor-pointer {
  cursor:pointer
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset!important;
  transition: background-color 5000s ease-in-out 0s;
}

.mw-10{
  width: 10px;
}
.mw-20{
  width: 20px;
}
.mw-30{
  width: 30px;
}
.mw-40{
  width: 40px;
}
.mw-50{
  width: 50px;
}
.mw-60{
  width: 60px;
}
.mw-70{
  width: 70px;
}
.mw-80{
  width: 80px;
}
.mw-90{
  width: 90px;
}
.mw-100{
  width: 100px;
}
.mw-110{
  width: 110px;
}
.mw-120{
  width: 120px;
}
.mw-130{
  width: 130px;
}
.mw-140{
  width: 140px;
}
.mw-150{
  width: 150px;
}
.text-center {
  text-align: center;
}

.alert {
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-size:15px;
  color:#fff;
  margin:30px 0;
  display: flex;
  align-items: center;
}
.alert p {
  margin:0px
}
.alert svg {
  flex:none
}
.alert-success {
  background: #28a745;
}
.alert-danger {
  background: #ce494c;
}
.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;  
}
.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
}
  
.alert-success2 {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}


//tooltip
[tooltip] {
  position: relative; /* opinion 1 */
}

[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 14px; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  font-family: Helvetica, sans-serif;
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.table {
  width: 100%;
  text-align: left;
  color: var(--gray-3);
  border-collapse: collapse;
  td, th {
    padding: 6px 28px;
    font-size: 15px;
  }
  thead {
    text-transform: uppercase;
    tr {
      border-top: 1px solid var(--gray-2)!important;
      border-bottom: 1px solid var(--gray-2)!important;
      th {
        color: var(--gray);
        font-size: .8rem;
        letter-spacing: 1px;
        padding: 16px 28px;
      }
    }
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
}
input[type="date"] {
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--gray-1);
  opacity: .5;
  z-index: 999;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0px;
    right: 0;
    bottom: 0;
    margin:auto;
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-top-color: var(--gray-5);
    animation: spin 1s infinite linear;
  }
}

@keyframes spin { 
  100% { 
    transform: rotate(360deg); 
  } 
} 

.dropdown {
  position: relative;
  &.active {
    .dropdown-bg, .dropdown-menu {
      display: block;
    }
  }
  .dropdown-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.2);
    display: none;
    min-height: 100vh;
    z-index: 899;
  }
  .dropdown-menu {
    position: absolute;
    white-space: nowrap;
    top:100%;
    right: 0;
    z-index: 9;
    padding:10px 0;
    background-color:#fff;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    min-width: 200px;
    border-radius: 5px;
    display: none;
    z-index: 900;

    max-height: 360px;
    overflow-y: auto;
    &.left{
      right: auto;
      left: 0;
    }
    ul {
      width:100%;
      li {
        width:100%;
        .a {
          width:100%;
          padding:10px 20px;
          display: block;
          background-color:transparent;
          transition:.2s all;
          cursor: pointer;
          &:hover {
            background-color:#eee;
          }
        }
      }
    }
  }
}