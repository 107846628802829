.headerSearch {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 260px;
    right: 0;
    top: 0;
    padding: 0px 26px;
    height: 62px;
    background-color: #fff;
    transition: .2s all;
    box-shadow: 0 0 0;
    z-index: 8;
    display: flex;
    align-items: center;
    box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
    opacity: 0;
    pointer-events: none;
    &.active {
        opacity: 1;
        pointer-events: all;
    }
    svg {
        height: 25px;
        width: 25px;
        cursor: pointer;
        margin:0 12px 0 0;
        path[fill]{
            fill:#5a8dee;
            transition: .2s all;
        }
        &:last-child {
            margin-right: 0px;
            margin-left: 12px;
        }
    }
    input {
        width: 100%;
        height: 52px;
        border: 0px;
        font-size: 15px;
        padding: 0 10px;
    }
}
.searchMain {
    position: fixed;
    top: 62px;
    left: 260px;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    padding: 10px;
    z-index: 7;
    opacity: 0;
    pointer-events: none;
    transition: .2s all;
    &.active {
        opacity: 1;
        pointer-events: all;
    }
    ul {
        background-color: #fff;
        border-radius: 5px;
        padding: 10px 0;
        li{
            width: 100%;
            a{
                width: 100%;
                padding: 8px 14px;
                color: var(--gray);
                font-size: 15px;
                display: flex;
                flex-direction: column;
                transition: .2s all;
                background-color: transparent;
                cursor: pointer;
                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
.header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 260px;
    right: 0;
    top: 0;
    padding: 0 36px;
    height: 62px;
    background-color: transparent;
    transition: .2s all;
    box-shadow: 0 0 0;
    z-index: 7;
    align-items: center;
    &.active {
        background-color: var(--white-bg);
        box-shadow: -8px 12px 18px 0 rgba(25,42,70,.1);
    }
    .left {
        display: flex;
        align-items: center;
        h1 {
            color: var(--gray);
            font-weight: 500;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 20px;
        }
    }
    .right {
        display: flex;
        align-items: center;
        .bgSwitch {
            position: relative;
            width: 42px;
            transition: all 150ms;
            opacity: 0.5;
            margin:0 12px;
            &:hover {
                opacity: 1;
            }
            .track {
                width: 42px;
                height: 24px;
                border-radius: 24px;
                border: 3px solid #1b78d0;
                background-color: #b8cee2;
                cursor: pointer;
            }
            .thumb {
                position: absolute;
                top: -2px;
                left: -2px;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: #1b78d0 url("data:image/svg+xml;utf8,%3Csvg style='fill: %23ffffff;' width='14' height='13' viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.52208 7.71754C7.5782 7.71754 10.0557 5.24006 10.0557 2.18394C10.0557 1.93498 10.0392 1.68986 10.0074 1.44961C9.95801 1.07727 10.3495 0.771159 10.6474 0.99992C12.1153 2.12716 13.0615 3.89999 13.0615 5.89383C13.0615 9.29958 10.3006 12.0605 6.89485 12.0605C3.95334 12.0605 1.49286 10.001 0.876728 7.24527C0.794841 6.87902 1.23668 6.65289 1.55321 6.85451C2.41106 7.40095 3.4296 7.71754 4.52208 7.71754Z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
                transition: transform 0.3s cubic-bezier(0.4, 0.03, 0, 1);
                cursor: pointer;
            }
            &.active {
                .thumb {
                    transform: translateX(18px);
                }
            }
        }
        .icons {
            display: flex;
            svg {
                height: 25px;
                width: 25px;
                cursor: pointer;
                margin:0 12px;
                path[fill]{
                    fill:var(--gray);
                    transition: .2s all;
                }
                &:hover path[fill] {
                    fill:#5a8dee;
                }
            }
        }
        .langs {
            display: flex;
            color: var(--gray-3);
            margin-right: 12px;
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
            svg {
                width: 23px;
                margin-right: 8px;
            }
        }
        .profile {
            display: flex;
            margin-left: 35px;
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
            .profileInfo {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-position: center;
                background-size: cover;
                margin-left: 14px;
            }
        }
    }
}

@media(max-width:991px){
    .headerSearch{
        left: 0px;
    }
    .header {
        left: 0px;
        padding:0 15px;
        .left {
            display: none;
        }
        .right {
            margin-left: auto;
            .langs {
                display: none;
            }
            .profile{
                margin-left: 10px;
            }
            .icons {
                svg {
                    // display: none;
                }
            }
        }
    }
}