.formGroup{ 
    width: 100%;
    background-color: var(--white-bg);
    display: block;
    // margin-bottom: 12px;
    position: relative;
    select{
        border:none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
        // & + label {
        //     top:5px;
        //     font-size: 13px;
        //     opacity: .7;
        // }
    }
    input:disabled{
        opacity: .5;
    }
    &.type_2{
        background-color:transparent;
        width:100%;
        label {
            display: none;
        }
        input, select, textarea {
            display: block;
            width: 100%;
            height: 42px;
            padding: 0 12px;
            font-size: 1rem;
            line-height: 1.4;
            color: var(--gray);
            background-color: var(--white-bg);
            background-clip: padding-box;
            border: 1px solid var(--gray-2);
            border-radius: .267rem;
            -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            &:focus {
                border:1px solid #0984e3
            }
            &:focus + label, &:valid + label {
                font-size: 13px;
                opacity: .7;
            }
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: var(--gray);
                opacity: .4;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: var(--gray);
                opacity: .4;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: var(--gray);  
                opacity: .4;          
            }
        }
    }
    &.type_1{
        background-color:transparent;
        label {
            color: var(--gray);
            font-size: .8rem;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 3px;
            display: block;
            text-align: left;
        }
        input, select, textarea {
            display: block;
            width: 100%;
            height: 42px;
            padding: 0 12px;
            font-size: 1rem;
            line-height: 1.4;
            color: var(--gray);
            background-color: var(--white-bg);
            background-clip: padding-box;
            border: 1px solid var(--gray-2);
            border-radius: .267rem;
            -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            &:focus {
                border:1px solid #0984e3
            }
            &:focus + label, &:valid + label {
                font-size: 13px;
                opacity: .7;
            }
        }
    }

  &.type_4{

    display:flex;
    // flex-direction:row-reverse;
    align-items: center;
    margin-bottom: 0;
    &.type_radio {
      &>div {
        flex:1;
      }
      label:nth-child(2){
        margin-left: 15px;
      }
    }
    .bar, .highlight {
      display: none;
    }
    .label {
      width:28%;
      font-weight: 400;
      // margin-right: 15px;
      position: relative;
      top:0!important;
      left:0!important;
      font-size: 15px!important;
      text-transform: inherit!important;
      margin-right: auto;
      word-break: break-word;
    }
     input, select, textarea {
      flex: 1;
      background-color: var(--white-bg);
      height: 42px;
      border-radius: 5px;
      border:1px solid var(--gray-2)!important;
      font-size: 15px;
      padding: 0 6px!important;
      color:var(--gray);
      width: 100%;
     }
     textarea {
       padding:6px!important;
       min-height: 120px;
     }
     i {
       color: #eb4f42!important
     }
  }
    &.dateRange {
        display: flex;
        background-color: transparent;
        margin-bottom: 0px;
        
        input {
            height: 30px;
            width: 85px;
            font-size: 12px;
            padding: 0 6px;
            flex:1;
            &:nth-child(1){
                // border-right: 0px;
                border-radius: 3px 0 0 3px;
            }
            &:nth-child(2){
                border-left: 0px;
                border-right: 0px;
                border-radius: 0;
            }
        }
        &.type_2{
            input {
                width: 110px;
                height:42px;
                font-size: 16px;
                text-align: center;
                // &:nth-child(2){
                //     border-right: 1px solid var(--gray-2);
                // }
            }
        }
    }
    &.textarea{
        height: 120px;
        textarea {
            height: 120px;
        }
    }



    &.type_radio{
        display: flex;
        background-color: var(--white-bg);
        width: 100%;
        height: 42px;
        border: 1px solid var(--gray-2);
        color:var(--gray);
        border-radius: 3px;
        margin-bottom: 0;
        position: relative;


        input, select, textarea {
            transition: .2s all;
            width: calc(100% + 2px);
            height: 52px;
            padding-top: 20px;
            border: 1px solid transparent;
            position: absolute;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border-radius: 3px;
            font-size: 16px;
            padding-left: 10px;
            background-color: transparent;
            top: -1px;
            &:focus {
                border:1px solid #0984e3
            }
            &:focus + label, &:valid + label {
                top:5px;
                font-size: 13px;
                opacity: .7;
            }
        }
        .bg {
            position: absolute;
            top:-1px;
            left:calc(50% + 1px);
            width: 50%;
            right: 0;
            bottom: -1px;
            background-color: #74b9ff;
            transition: .4s all;
            border-radius: 3px;
            border-radius: 0 3px 3px 0;
            &.activeBg {
                background-color: #ff9ff3;
                left: -1px;
                border-radius: 3px 0 0 3px;
            }
            &.activeBg2 {
                background-color: var(--success);
                left: -1px;
                border-radius: 3px 0 0 3px;
            }
        }
        div{
            flex:1;
            position: relative;
            cursor: pointer;
            z-index: 2;
            height:42px;
            display: flex;
            input {
                opacity: 0;
                cursor: pointer;
                height:42px;
                &:checked + label {
                    color: #fff!important;
                    // background-color: #f368e0;
                    // border-radius: 3px 0 0 3px;
                }
            }
            &:nth-child(2){
                input {
                    &:checked + label {
                        color: #fff!important;
                        // background-color: #3498db;
                        // border-radius: 0 3px 3px 0px;
                    }
                }
            }
            label{
                top:0;
                left: 0;
                opacity: 1!important;
                height: auto;
                bottom: 0;
                margin: auto;
                width: 100%;
                font-size: 15px!important;
                color:var(--gray)!important;
                background-color: transparent;
                transition: .2s all;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }
}
